<template>
<div>
<p class="pricetext">Total Cost: ${{totalPrice}}</p>
<form class="center" action="https://youtu.be/oavMtUWDBTM">
    <input type="submit" value="Proceed to Checkout" />
</form>
<div class="wrapper">
  <div class="products">
    <div class="product" v-for="product in this.$root.$data.cart" :key="product.id">
      <div class="info">
        <h1>{{product.name}}</h1>
      </div>
      <div class="image">
        <img :src="'/images/products/'+product.image">
      </div>
      <div class="price">
        <h2>{{product.price}}</h2>
        <button class="auto" v-on:click="removeFromCart(product)">Remove</button>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
export default {
  name: 'Cart',
  props: {
    products: Array
  },
  computed: {
    cart() {
      return this.$root.$data.cart;
    },
    totalPrice() {
      let totalCost = 0;
      for( var i = 0; i < this.$root.$data.cart.length; i++){ 
          let thisCost = parseFloat(this.$root.$data.cart[i].price.substring(1));
          totalCost = totalCost + thisCost;
        }
      return totalCost.toFixed(2);
    }
  },
  methods: {
    removeFromCart(product) {
      console.log(product.id);
      for( var i = 0; i < this.$root.$data.cart.length; i++){ 
            if ( this.$root.$data.cart[i].id === product.id) { 
                this.$root.$data.cart.splice(i, 1);
                break;
            }
        }
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.products {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.product {
  margin: 10px;
  margin-top: 50px;
  width: 200px;
  background:#86b8cf;
}

.product img {
  border: 2px solid #333;
  height: 200px;
  width: 200px;
  object-fit: cover;
}

.product .image {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.info {
  background: #170b80;
  font-family: "Lucida Sans Typewriter";
  color: #000;
  text-align: center;
  font-size:32px;
  color:white;
  height:18px;
}

.info h1 {
  font-size: 16px;
}

.info h2 {
  font-size: 14px;
}

.info p {
  margin: 0px;
  font-size: 10px;
}


.price {
  display: flex;
}

button {
  height: 50px;
  background: #000;
  color: white;
  border: none;
}

.auto {
  margin-left: auto;
  margin-right:15px;
  margin-top:5px;
  background:#170b80;
}

.center{
  text-align:center;
}

.pricetext{
  text-align:center;
  font-size:36px;
  font-family: "Lucida Sans Typewriter";
  margin:15px;
}
</style>