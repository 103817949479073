<template>
<div id="app">
  <div id="menu">
    <div id="brand">
      <router-link to="/">
        <img src="images/logo.png">
      </router-link>
    </div>
    <div id="side">
      <router-link to="/cart">
        <div class="menu-item">
          <img class="carticon" src="/images/love.png">
          <p>{{totalItems}} items</p>
        </div>
      </router-link>
    </div>
  </div>
  <p class="TopText">Creative NFTs crafted by Christian</p>
  <router-view />
</div>


</template>

<script>
export default {
  name: 'App',
  computed: {
    totalItems: function () {
      return this.$root.$data.cart.length;
    }
  },
}

</script>

<style>
* {
  box-sizing: border-box;
}

body {
  background:#7690a8;
}

#menu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5px;
  grid-template-areas: "none brand side";
  background:#170b80;
}

#menu a {
  color: white;
}

#brand {
  grid-area: brand;
  display: flex;
  justify-content: center;
}

#brand img {
  height: 125px;
  margin-top:25px;
}

#side {
  grid-area: side;
  display: flex;
  justify-content: left;
  text-align:center;
  background:#170b80;
}

#side img {
  width: 75px;
}

.menu-item {
  display: flex;
  flex-direction: column;
  padding:40px;
}

.menu-item p {
  margin: 0px;
}

.browse {
  margin-right: 50px;
}

.footer {
   position:fixed;
   left: 0;
   bottom: 0;
   padding: 15px;
   width: 100%;
   background-color: orange;
   color: white;
   text-align: center;
}

.footer-link{
    padding: 50px;
    color:black;
    font-size: larger;
}

.TopText{
  color:white;
  font-size:24px;
  text-align:center;
  margin:0px;
  background:#170b80;
  padding-bottom:10px;
  padding-top:0px;
  font-family: "Lucida Sans Typewriter";
}
</style>
