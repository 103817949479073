let mock = [{
    id: 1,
    name: "#56627",
    price: "$5.62",
    country: "United States",
    image: '001.png'
  },
  {
    id: 2,
    name: "#88309",
    price: "$7.41",
    country: "Brazil",
    image: "002.png"
  },
  {
    id: 3,
    name: "#19794",
    price: "$5.92",
    country: "United States",
    image: '003.png'
  },
  {
    id: 4,
    name: "#58814",
    price: "$3.58",
    country: "United States",
    image: '004.png'
  },
  {
    id: 5,
    name: "#36196",
    price: "$7.47",
    country: "Canada",
    image: '005.png'

  },
  {
    id: 6,
    name: "#37193",
    price: "$8.77",
    country: "Brazil",
    image: '006.png'
  },
  {
    id: 7,
    name: "#63419",
    price: "$9.24",
    country: "Brazil",
    image: '007.png'
  },
  {
    id: 8,
    name: "#62443",
    price: "$3.02",
    country: "Canada",
    image: '008.png'
  },
  {
    id: 9,
    name: "#72719",
    price: "$4.76",
    country: "Brazil",
    image: '009.png'

  },
  {
    id: 10,
    name: "#",
    price: "$3.50",
    country: "Brazil",
    image: '010.png'
  },
  {
    id: 11,
    name: "#19390",
    price: "$6.70",
    country: "United States",
    image: "011.png"
  },
  {
    id: 12,
    name: "#16124",
    price: "$7.14",
    country: "Canada",
    image: "012.png"
  },
  {
    id: 13,
    name: "#83504",
    price: "$6.92",
    country: "Brazil",
    image: "013.png"
  },
  {
    id: 14,
    name: "#65607",
    price: "$5.36",
    country: "Mexico",
    image: "014.png"
  },
  {
    id: 15,
    name: "#12048",
    price: "$4.18",
    country: "United States",
    image: "015.png"
  },
  {
    id: 16,
    name: "#24792",
    price: "$9.18",
    country: "Mexico",
    image: "016.png"
  },
  {
    id: 17,
    name: "#55118",
    price: "$4.73",
    country: "Brazil",
    image: "017.png"
  },
  {
    id: 18,
    name: "#28734",
    price: "$5.16",
    country: "Canada",
    image: "018.png"
  },
  {
    id: 19,
    name: "#95724",
    price: "$6.81",
    country: "United States",
    image: "019.png"
  },
  {
    id: 20,
    name: "#41587",
    price: "$8.62",
    country: "United States",
    image: "020.png"
  },
  {
    id: 21,
    name: "#62303",
    price: "$3.16",
    country: "United States",
    image: "021.png"
  },
  {
    id: 22,
    name: "#18923",
    price: "$9.41",
    country: "Brazil",
    image: "022.png"
  },
  {
    id: 23,
    name: "#75597",
    price: "$7.42",
    country: "Brazil",
    image: "023.png"
  },
  {
    id: 24,
    name: "#40430",
    price: "$3.53",
    country: "Brazil",
    image: "024.png"
  },
  {
    id: 25,
    name: "#40696",
    price: "$9.90",
    country: "Mexico",
    image: "025.png"
  },
  {
    id: 26,
    name: "#50381",
    price: "$8.67",
    country: "Canada",
    image: "026.png"
  },
  {
    id: 27,
    name: "#61068",
    price: "$2.64",
    country: "Canada",
    image: "027.png"

  },
  {
    id: 28,
    name: "#48995",
    price: "$8.72",
    country: "Brazil",
    image: "028.png"
  },
  {
    id: 29,
    name: "#72695",
    price: "$9.86",
    country: "Brazil",
    image: "029.png"
  },
  {
    id: 30,
    name: "#14363",
    price: "$5.85",
    country: "United States",
    image: "030.png"

  },
  {
    id: 31,
    name: "#99080",
    price: "$4.49",
    country: "United States",
    image: "031.png"
  },
  {
    id: 32,
    name: "#69593",
    price: "$8.85",
    country: "United States",
    image: "032.png"
  },
  {
    id: 33,
    name: "#",
    price: "$6.67",
    country: "Brazil",
    image: "033.png"
  },
  {
    id: 34,
    name: "#38408",
    price: "$4.88",
    country: "Brazil",
    image: "034.png"

  },
  {
    id: 35,
    name: "#21676",
    price: "$4.02",
    country: "Brazil",
    image: "035.png"
  },
  {
    id: 36,
    name: "#28084",
    price: "$4.45",
    country: "Canada",
    image: "036.png"
  },
  {
    id: 37,
    name: "#71153",
    price: "$9.24",
    country: "Canada",
    image: "037.png"
  },
  {
    id: 38,
    name: "#87626",
    price: "$5.32",
    country: "Mexico",
    image: "038.png"
  },
  {
    id: 39,
    name: "#36023",
    price: "$5.12",
    country: "United States",
    image: "039.png"
  },
  {
    id: 40,
    name: "#89599",
    price: "$9.22",
    country: "Brazil",
    image: "040.png"
  },
  {
    id: 41,
    name: "#36994",
    price: "$8.78",
    country: "Brazil",
    image: '041.png'
  },
  {
    id: 42,
    name: "#11128",
    price: "$9.03",
    country: "Brazil",
    image: '042.png'
  },
  {
    id: 43,
    name: "#72175",
    price: "$9.36",
    country: "United States",
    image: '043.png'
  },
  {
    id: 44,
    name: "#48202",
    price: "$8.76",
    country: "Mexico",
    image: '044.png'
  },
  {
    id: 45,
    name: "#86880",
    price: "$49.99",
    country: "United States",
    image: '045.png'
  }
]

export default mock;
